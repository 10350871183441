<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import CreateForm from "./components/createForm.vue";
    import moment from "moment";
    import Swal from 'sweetalert2';
    
    export default {
        page: {
            title: "Edit Shift",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
        components: {
            Layout,
            PageHeader,
            CreateForm
        },
        data() {
            return {
                processing:false,
                location_id:null,
                areas: [{
                    id:null,
                    area_id: null,
                    roles: null,
                    area_roles: [{
                        id:null,
                        count:null,
                        role_count:null,
                        remaining_slots:null,
                    }],
                }],
                  shift:
               { 
                shift_type:'Non_Premium',
                bonus:null,
                name:null,
                interval: null,
                from: null,
                to: null,
                location: null,
                desc: null,
                label_color: null,
                }, 
                title: "Edit Shift",
                items: [
                    {
                        text: "Shift",
                    },
                    {
                        text: "Edit Shift",
                        active: true,
                    },
                ],
                
            };
        },
         mounted(){
        this.fetchShift();
        },

     
        methods: {
            fetchShift() {
                this.$axios.get("/shift/edit/"+this.$route.params.shift_id).then((response) => {
                let data = response.data.payload;
                this.shift=data.shift;
                // this.shift.from=this.changeDateTimeToLocalFormats(data.shift.from);
                // this.shift.to=this.changeDateTimeToLocalFormats(data.shift.to);
                
                let fromDate = data.shift.from.split(".");
                let toDate = data.shift.to.split(".");
                this.shift.from= fromDate[0];
                this.shift.to= toDate[0];


                this.areas=data.areas;
                this.shift.area_roles.map((role)=>{
                    this.inserCount(role);

                })
                this.location_id=data.shift.location_id;
                
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

            inserCount(role){
                 this.areas.map((area)=>{
                     area.area_role.map((a_role)=>{
                         if(a_role.id==role.id){
                             a_role.count=role.pivot.role_count
                             a_role.remaining_slots = role.pivot.remaining_slots
                             a_role.role_count = role.pivot.role_count
                         }
                     })
                })
            },

    uploadShift() {

           
           Swal.fire({
        title: "Do you want to update all linked shifts?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: "#70c213",
        cancelButtonColor: "#34c38f",
        denyButtonColor: "#70c213",
          denyButtonText: "Update All Linked Shifts!",
        confirmButtonText: "Update!",
      }).then((result) => {
          let isUpdate=null;
             self.processing = true;
        if (result.isConfirmed) {
            this.updateShift(false);
        } else if (result.isDenied) {
            this.updateShift(true);
         }
           
        
      }); 
               
            
          
    },

    updateShift(isUpdate){

           let self = this;
            if (self.processing) {
                return;
            }
            self.processing = true;
        self.$axios.post('/shift/update/'+this.$route.params.shift_id, {
                id:this.$route.params.shift_id,
                name: self.shift.name,
                desc:self.shift.desc,
                from:self.shift.from,
                to: self.shift.to,
                day: self.shift.interval,
                shift_type:self.shift.shift_type,
                bonus:self.shift.bonus,
                chunk:self.shift.chunk,
                location_id:self.location_id,
                label_color:self.shift.label_color,
                areas:self.areas,
                parent_id:self.shift.parent_id,
                update_all: isUpdate,
                utc_from : this.changeDateTimeToUTCZero(self.shift.from),
                utc_to : this.changeDateTimeToUTCZero(self.shift.to),
                
          }).then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    
                     if(this.shift.duplicate){
                       this.shift.duplicate_status=true;
                       }else{
                            self.$router.push('/shifts');
                    }
                                     
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });

    }

        },

        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" />
            <div class="card p-4 radius-20">
            <create-form  :areas="areas" disable-from-date disable-to-date :location_id="location_id" :shift="shift" ></create-form>
                <div class="col-md-12">
                
                <SubmitButton text="Update Shift" @clicked="uploadShift" :processing="processing"></SubmitButton>
            </div>
        </div>
    </Layout>
</template>
